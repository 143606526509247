@import "https://fonts.googleapis.com/css?family=Yaldevi:400|PT+Serif:700|Bebas+Neue:400";
*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd, .reset {
  margin: 0;
  padding: 0;
}

ul[role="list"], ol[role="list"] {
  padding: 0;
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

:root {
  --accent-color: #6b78f2;
  --blue-dark: #00153b;
  --blue-darker: #141838;
  --blue-light: #9fb4f4;
  --blue-lighter: #b8ccff;
  --red: #ff5959;
  --white: white;
  --black: #102030;
  --text: var(--black);
  --transparent: rgba(255, 255, 255, 0);
  --small-screen-max: 400px;
  --medium-screen-min: 800px;
  --medium-screen-max: 1080px;
  --large-screen-min: 1200px;
}

.visually_hidden {
  clip-path: circle(0);
  margin: 0;
  padding: 0;
  font-size: 0;
}

@media (max-width: 600px) {
  .hidden_on_mobile {
    clip-path: circle(0);
    margin: 0;
    padding: 0;
    font-size: 0;
  }
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
  font-size: 125%;
}

@media (max-width: 30em) {
  html {
    font-size: 100%;
  }
}

body {
  background-color: #fff;
  background-color: var(--white);
  color: #102030;
  color: var(--black);
  max-width: 100vw;
  overscroll-behavior-y: none;
  background-image: url("video-faking_tmp.a37fd190.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-family: Yaldevi, sans-serif;
  font-weight: 400;
  line-height: 1.7;
  position: relative;
  overflow-x: hidden;
}

.back_to_top {
  position: absolute;
  top: 130vh;
  bottom: 2rem;
  left: auto;
  right: 2rem;
}

#back_to_top {
  z-index: 100;
  padding: .7em;
  display: none;
  position: sticky;
  top: 90vh;
}

@media (min-width: 1200px) {
  #back_to_top {
    display: block;
  }
}

p {
  margin-bottom: 1rem;
}

h1, h2, h3, h4, h5, .title {
  text-align: center;
  margin: 1.38rem 0;
  font-family: Bebas Neue, sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1, .h1 {
  margin: 0;
  font-size: 2.488rem;
}

h2, .h2 {
  font-size: 2.074rem;
}

h3, .h3 {
  font-size: 1.728rem;
}

h4, .h4 {
  font-size: 1.44rem;
}

h5, .h5 {
  font-size: 1.2rem;
}

small, .text_small {
  font-size: .833rem;
}

img {
  max-width: 100%;
  object-fit: contain;
}

.no_bullet {
  list-style-type: none;
}

a.link {
  background: linear-gradient(to right, var(--blue-dark), var(--blue-dark), var(--blue-dark)), linear-gradient(to right, #db4664, #db4664);
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  background-size: 100% .1em, 0 .1em;
  font-weight: 600;
  text-decoration: none;
  transition: background-size .4s;
}

a.link:hover, a.link:focus {
  background-size: 0 .1em, 100% .1em;
}

a.button {
  background-color: var(--accent-color);
  color: var(--white);
  text-align: center;
  border-radius: 5px;
  padding: .8rem 1.3rem;
  text-decoration: none;
  transition: all .14s ease-in-out;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .25);
}

a.button:hover {
  background-color: var(--blue-dark);
  transition: all .14s ease-in-out;
}

a[href^="http"]:not(.non_textual):after {
  content: url("icon-external-link-black.2fce65cd.svg");
  width: 20px;
  height: 20px;
  justify-content: center;
  margin-left: 5px;
  display: inline-flex;
}

a[href^="http"]:not(.non_textual).button:after, a[href^="http"]:not(.non_textual).ext_contrast:after {
  content: url("icon-external-link-white.9121b332.svg");
}

a.button[disabled], button[disabled] {
  cursor: default;
  color: gray;
  box-shadow: none;
  background: #d3d3d3;
}

article a:not(.button) {
  color: var(--blue-dark);
}

.non_breakable {
  white-space: nowrap;
}

img.picture {
  border: 1px solid var(--blue-dark);
  box-shadow: 0 0 0 var(--accent-color);
  border-radius: 20px;
  transition: all .1s ease-in;
}

img.picture:hover {
  box-shadow: 6px 6px 0px var(--accent-color);
  transition: all .1s ease-in;
}

.align_center > p, p.align_center {
  text-align: center;
}

.align_right > p, p.align_right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.flex_row {
  flex-flow: row;
  display: flex;
}

@media (max-width: 600px) {
  .flex_row.responsive {
    flex-flow: column;
  }
}

.flex_column {
  flex-flow: column;
  display: flex;
}

.flex_row_breaker {
  flex-basis: 100%;
}

.wrap {
  flex-wrap: wrap;
}

header.main_header {
  color: #fff;
  scroll-snap-align: start;
}

header.main_header h1 {
  font-size: 1.2em;
}

header.main_header .header_wrapper {
  background-color: var(--blue-dark);
  justify-content: space-between;
  align-items: center;
  margin-bottom: -1px;
  padding: 1em;
  position: relative;
}

header.main_header .header_layer_cake {
  max-width: 100%;
}

nav .menu_item {
  margin: .5em 1em;
}

nav .menu_item a {
  color: var(--white);
  text-decoration: none;
}

nav .navigation_wrapper {
  height: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

#burger_menu_switch, .burger_button {
  display: none;
}

@media (max-width: 1000px) {
  .logo_wrapper {
    z-index: 200;
  }

  .header_navigation {
    background-color: var(--blue-dark);
    z-index: -200;
    opacity: 0;
    transition: all .3s ease-out;
    position: absolute;
    top: 100%;
    bottom: auto;
    left: 0;
    right: 0;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, .27);
  }

  #burger_menu_switch:checked ~ .header_navigation {
    z-index: 200;
    opacity: 1;
    transition: opacity .3s ease-out;
  }

  #burger_menu_switch:checked ~ .burger_button {
    background-image: url("icon-circle-x.8ce42514.svg");
  }

  .burger_button {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    background-image: url("icon-burger-menu.9c7f5993.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
  }

  nav .navigation_wrapper {
    flex-flow: column;
    padding: 1em;
    position: relative;
  }
}

footer.main_footer {
  color: #fff;
  background-color: var(--blue-dark);
  scroll-snap-align: end;
}

footer.main_footer h1 {
  font-size: 1.2em;
}

footer.main_footer .header_wrapper {
  justify-content: space-between;
  padding: 1em;
}

footer.main_footer .footer_layer_cake {
  max-width: 100%;
}

.synergie_brand {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.synergie_brand > a {
  margin: 1em;
}

.synergie_brand > a img {
  width: 4rem;
}

.synergie_brand > .text_wrapper p {
  text-align: center;
  margin: 0;
}

.footer_links__container {
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
}

.footer_links__container .footer_link {
  margin: 1em;
}

.footer_links__container .footer_link a {
  color: var(--white);
}

.synergie_brand__item {
  max-width: 100%;
  padding: 1rem;
}

.synergie_brands {
  justify-content: center;
  align-items: center;
}

.synergie_brand__item.aile_medicale {
  width: 13em;
}

.synergie_brand__item.sandyou {
  width: 6em;
}

.synergie_brand__item.dialogue_competences {
  width: 20em;
}

@media (max-width: 800px) {
  .synergie_brands {
    flex-flow: column;
  }
}

main > * {
  position: relative;
}

main > article:not(.hero_header) {
  min-height: 100vh;
  background-color: var(--white);
  scroll-snap-align: start;
  padding: 2rem .5rem;
}

main > article h2 {
  text-align: center;
}

main > article.hero_header {
  min-height: 500px;
  background-color: var(--transparent);
}

.content_wrapper.max_width {
  max-width: 1200px;
  margin: auto;
}

@media (min-width: 800px) {
  main > article {
    padding: 3rem;
  }
}

@media (min-width: 1500px) {
  main > article {
    padding: 5rem;
  }
}

article.hero_header .content_wrapper {
  max-height: max-content;
  backdrop-filter: blur(7px);
  background: rgba(99, 99, 99, .3);
  border-radius: 5px;
  justify-content: space-around;
  padding: 4rem;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, .25);
}

@media (max-width: 1200px) {
  article.hero_header .content_wrapper {
    margin: auto;
    padding: 2rem;
  }
}

article.hero_header h2 {
  color: var(--white);
  text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

.offers_buttons_wrapper > .button {
  margin: .5em;
}

article .slider_container {
  background-color: var(--white);
  display: flex;
}

article .slider_container .splide__slide {
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

@media (max-width: 600px) {
  article .slider_container .splide__slide {
    flex-flow: column;
  }

  .integrated_video__YT {
    max-width: 100%;
  }
}

article .slider_container .splide__slide > * {
  padding: 2em;
}

@media screen and (min-width: 1100px) {
  article .slider_container .splide__slide .slide__text_container {
    flex: 0 60%;
  }

  article .slider_container .splide__slide .slide__picture_container {
    flex: 0 25%;
  }
}

article .slider_container .splide__arrow {
  background: var(--transparent);
}

.slider_container .splide__arrow--prev {
  left: .3em;
}

.slider_container .splide__arrow--next {
  right: .3em;
}

.splide__track {
  padding-bottom: 1em;
}

article .slider_container .splide__arrow svg {
  height: 3.2em;
  width: 3.2em;
}

article .slider_container .splide__pagination__page {
  background: var(--blue-darker);
  width: 20px;
  height: 20px;
}

article .slider_container .splide__pagination__page.is-active {
  background: var(--accent-color);
  transform: scale(1.2);
}

article.clients .brands_container {
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
}

article.clients .brands_container .brand_item {
  padding: 3rem;
}

article.clients .brands_container .brand_item img {
  width: 10rem;
}

article.clients .brands_container .brand_item__break {
  flex-basis: 100%;
}

article.clients .logo_container {
  justify-content: center;
  padding: 3rem;
}

article.clients .logo_container img {
  max-width: 10rem;
}

#qui-sommes-nous .content_wrapper > * {
  margin: 4rem 0;
}

.domains_container {
  justify-content: center;
}

.domains_container .about__picto {
  text-align: center;
}

.domains_container .about__picto:first-of-type {
  margin-right: 5rem;
}

.domains_container .about__picto > img {
  width: 8rem;
}

.show_da_numbez {
  flex-wrap: wrap;
  align-items: center;
}

.show_da_numbez .observed_item {
  min-width: 15rem;
  opacity: 0;
  flex: 1 0 33.3333%;
  padding: 2rem;
  line-height: 150%;
}

.show_da_numbez .observed_item.is_scrolled {
  opacity: 1;
  transition: opacity 2.5s ease-out;
}

.count_animation, .unit {
  color: var(--red);
  font-size: 2rem;
}

.logos_container {
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.logos_container .logo_item {
  width: 5rem;
  margin: 1.5em;
}

.separator {
  border-top: 1px solid var(--black);
  margin: 3rem;
}

.background_video {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: -5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fake_parallax {
  min-height: 75vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@supports selector(:nth-child(1 of x)) {
  .fake_parallax {
    background-attachment: initial;
  }
}

.fake_parallax:before, .fake_parallax:after, .bevel_top:before, .bevel_top:after, .bevel_bottom:before, .bevel_bottom:after {
  content: "";
  height: 4rem;
  background-color: var(--white);
  position: absolute;
}

.fake_parallax:before, .bevel_top:before {
  clip-path: polygon(100% 0%, 0% 100%, 0% 0%);
  top: -1px;
  bottom: auto;
  left: 0;
  right: 0;
}

.fake_parallax:after, .bevel_bottom:after {
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
  top: auto;
  bottom: -1px;
  left: 0;
  right: 0;
}

.count_animation {
  will-change: contents;
  display: inline-flex;
}

.count_animation:before {
  content: " ";
  min-width: 1px;
  flex: 3em;
  display: block;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade_in_up {
  animation: 1s ease-out fadeInUp;
}

.error_404 .content_wrapper {
  max-width: 1000px;
  background-color: var(--white);
  border-radius: 5px;
  align-items: center;
  margin: 5rem auto;
  padding: 2.5rem;
}

.error_404 .content_wrapper img {
  margin-bottom: 1rem;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  outline: none;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  outline: none;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}

.splide__spinner {
  contain: strict;
  height: 20px;
  width: 20px;
  border: 2px solid #999;
  border-left-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  margin: auto;
  animation: 1s linear infinite splide-loading;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.splide--draggable > .splide__slider > .splide__track, .splide--draggable > .splide__track {
  -ms-user-select: none;
  user-select: none;
}

.splide--fade > .splide__slider > .splide__track > .splide__list, .splide--fade > .splide__track > .splide__list {
  display: block;
}

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide, .splide--fade > .splide__track > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active, .splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
  position: relative;
}

.splide--rtl {
  direction: rtl;
}

.splide--ttb.is-active > .splide__slider > .splide__track > .splide__list, .splide--ttb.is-active > .splide__track > .splide__list {
  display: block;
}

.splide__arrow {
  cursor: pointer;
  height: 2em;
  opacity: .7;
  width: 2em;
  z-index: 1;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover {
  opacity: .9;
}

.splide__arrow:focus {
  outline: none;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide__pagination {
  z-index: 1;
  padding: 0 1em;
  position: absolute;
  bottom: .5em;
  left: 0;
  right: 0;
}

.splide__pagination__page {
  height: 8px;
  opacity: .7;
  width: 8px;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: transform .2s linear;
  display: inline-block;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9;
}

.splide__pagination__page:focus {
  outline: none;
}

.splide__progress__bar {
  height: 3px;
  background: #ccc;
}

.splide--nav > .splide__slider > .splide__track > .splide__list > .splide__slide, .splide--nav > .splide__track > .splide__list > .splide__slide {
  cursor: pointer;
  border: 3px solid rgba(0, 0, 0, 0);
}

.splide--nav > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active, .splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide--nav > .splide__slider > .splide__track > .splide__list > .splide__slide:focus, .splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none;
}

.splide--rtl > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__slider > .splide__track > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide--rtl > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__slider > .splide__track > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide--rtl > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__slider > .splide__track > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide--rtl > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__slider > .splide__track > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide--ttb > .splide__arrows .splide__arrow, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow, .splide--ttb > .splide__track > .splide__arrows .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide--ttb > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
  top: 1em;
}

.splide--ttb > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide--ttb > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
  top: auto;
  bottom: 1em;
}

.splide--ttb > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__slider > .splide__track > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide--ttb > .splide__pagination, .splide--ttb > .splide__slider > .splide__pagination {
  flex-direction: column;
  padding: 1em 0;
  display: flex;
  top: 0;
  bottom: 0;
  left: auto;
  right: .5em;
}

/*# sourceMappingURL=index.f0d1a019.css.map */
