@import url('https://fonts.googleapis.com/css?family=Yaldevi:400|PT+Serif:700|Bebas+Neue:400');

@import "_reset.css";
@import "_root.css";
@import "_layout.css";
@import "_elements.css";
@import "_flexbox.css";
@import "_header.css";
@import "_footer.css";
@import "_article.css";
@import "_aesthetics.css";
@import "_404.css";